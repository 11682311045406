"use client";
import {
  SupportedChainIds,
  SupportedChains,
  externalWalletModule,
  verisModule,
} from "clients/verisModule";
import { useCustomParams } from "logic/hooks";
import { usePathname } from "next/navigation";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WalletContext } from "./WalletProvider";
import { useUpdateEffect } from "react-use";
import { VerisChain } from "@unlockdfinance/verislabs-web3";

type IContext = {
  chainIdConnected?: number | null;
  chainConnected?: VerisChain;
  isSupportedChain: boolean;
  chainId: SupportedChainIds;
  chain: VerisChain;
  isChainBlocked: boolean;
};

export const ChainContext = createContext<IContext>({
  chainIdConnected: externalWalletModule.chainId,
  isSupportedChain: false,
  chainId: verisModule.chain.data.id,
  chain: verisModule.chain.data,
  isChainBlocked: false,
});

export const ChainProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    searchParams,
    getParamValue,
    removeAllValuesFromParam,
    setOrReplaceParamValue,
  } = useCustomParams();
  const pathname = usePathname();
  const defaultChain = useMemo(
    () =>
      verisModule.networks.find((network) =>
        getParamValue("chain")?.includes(network.CHAIN.name) ||
        pathname.includes(network.CHAIN.name)
      )?.CHAIN || verisModule.chain.data,
    []
  );
  const [isSupportedChain, setSupportedChain] = useState<boolean>(false);
  const [chain, setChain] = useState<VerisChain>(defaultChain);
  // console.log(`pathname: ${pathname}`)
  // console.log(`chain: ${chain.id}`)
  const [isChainBlocked, setChainBlocked] = useState<boolean>(
    pathname.includes("earn") ||
    verisModule.networks.some((network) =>
      pathname.includes(network.CHAIN.name)
    )
  );
  const walletObj = useContext(WalletContext);

  useEffect(() => {
    const networkFromPath = verisModule.networks.find((network) =>
      pathname.includes(network.CHAIN.name)
    );

    if (networkFromPath) {
      setChain(networkFromPath.CHAIN);
    }

    setChainBlocked(!!networkFromPath || pathname.includes("earn"));
  }, [pathname]);

  useUpdateEffect(() => {
    // Find if the chain is supported
    const chain = verisModule.supportedChains.find(
      (chain) => chain.id === walletObj.chainId
    );

    if (!isChainBlocked) {
      if (walletObj.chainId) {
        // If the chain is not supported
        // Remove the param from the URL
        // Keep the chainId previous
        if (!chain) {
          removeAllValuesFromParam("chain");
          // If the chain is supported
          // Set the chainId
          // It is not necceary to set the chain on verisModule
        } else {
          setChain(chain);
          verisModule.setChain(chain.id);
          setOrReplaceParamValue("chain", chain.name);
        }
      }
    }

    setSupportedChain(!!chain);
  }, [walletObj.chainId, isChainBlocked]);

  useEffect(() => {
    if (!isChainBlocked) {
      const chainParam = getParamValue("chain");

      // If the chain param is present
      if (chainParam) {
        // Check if the chain is supported
        const chain = verisModule.supportedChains.find(
          (chain) => chain.name === chainParam
        );

        // If it is supported set the chainId on verisModule and state
        if (chain) {
          setChain(chain);
          verisModule.setChain(chain.id);
        } else {
          // If the chain is not supported
          // Remove the param
          removeAllValuesFromParam("chain");
        }
      }
    } else {
      removeAllValuesFromParam("chain");
    }
  }, [searchParams, isChainBlocked]);

  return (
    <ChainContext.Provider
      value={{
        chainIdConnected: walletObj.chainId,
        chainConnected: walletObj.chain,
        isSupportedChain,
        chainId: chain.id,
        chain,
        isChainBlocked,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export const useChain = () => {
  const chainObj = useContext(ChainContext);

  return chainObj;
};

"use client";
import { useEffect, useMemo, useState } from "react";
import { handleErrors } from "../../errors";
import { useErrorAlert } from "./useErrorAlert";
import { useCustomWallet } from "../../components/providers";
import { Address } from "viem";
import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";
import { IErc20Currency } from "logic/types/currency/ICurrency";

export const useAllowance = ({
  amount,
  spender,
  erc20Currency,
}: // erc20Currency = currenciesModule.getDefaultCurrency(),
  {
    amount?: bigint;
    spender?: Address;
    erc20Currency?: IErc20Currency;
  }) => {
  const [allowance, setAllowance] = useState<bigint | undefined>();
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const { address } = useCustomWallet();

  useEffect(() => {
    if (address && spender && erc20Currency) {
      handleGetAllowance(address, spender, erc20Currency);

      return () => setAllowance(BigInt(0));
    }
  }, [address, spender, erc20Currency]);

  const isApproved = useMemo<boolean>(
    () =>
      amount !== undefined && allowance !== undefined && allowance >= amount,
    [allowance, amount]
  );

  const handleGetAllowance = async (
    owner: Address,
    spender: Address,
    erc20Currency: IErc20Currency
  ) => {
    try {
      setAllowance(await erc20Currency.contract.allowance(owner, spender, erc20Currency.chain.id));
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleApprove = async (
    _amount?: bigint,
    options?: OptionsWriteMethod
  ) => {
    if (spender && erc20Currency && address) {
      const correctedAmount = _amount || amount;

      if (correctedAmount !== undefined && correctedAmount > BigInt(0)) {
        await erc20Currency.approve(spender, correctedAmount, options);

        setAllowance((prevValue) => (prevValue || BigInt(0)) + correctedAmount);

        setTimeout(
          () => handleGetAllowance(address, spender, erc20Currency),
          10000
        );
      }
    }
  };

  return { allowance, handleApprove, isApproved };
};

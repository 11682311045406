"use client";
import { useModalConnectWallets } from "components/hooks/useModalConnectWallets";
import { useModalSign } from "components/hooks/useModalSign";
import { useModalSwitchNetwork } from "components/hooks/useModalSwitchNetwork";
import { useCustomWallet } from "components/providers";
import { useUnlockdSession } from "components/providers/UnlockdSessionProvider";
import { useChain } from "components/providers/ChainProvider";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

export const useActionProtected = (action: () => void): (() => void) => {
  const { isConnected } = useCustomWallet();
  const { chainId, chainIdConnected } = useChain();
  const { isLoggedIn } = useUnlockdSession();
  const [sensor, toggle] = useState<boolean>(false);
  const [isProcessInitiated, setProcessInitiated] = useState<boolean>(false);
  const [, toggleModalConnectWallet] = useModalConnectWallets();
  const [, toggleModalSwitchNetwork] = useModalSwitchNetwork();
  const [, toggleModalSign] = useModalSign();

  const launcher = () => {
    toggle(!sensor);
    setProcessInitiated(true);
  };

  useUpdateEffect(() => {
    if (isProcessInitiated) {
      if (!isConnected) {
        toggleModalConnectWallet();
      } else if (chainId !== chainIdConnected) {
        toggleModalSwitchNetwork();
      } else if (!isLoggedIn) {
        toggleModalSign();
      } else {
        setProcessInitiated(false);
        action();
      }
    }
  }, [sensor, isConnected, chainId, isLoggedIn, chainIdConnected]);

  return launcher;
};

import { verisModule } from "clients/verisModule";
import { useChain } from "components/providers/ChainProvider";
import { useEffect, useState } from "react";

export const useFaucetEnabled = () => {
  const [isFaucetEnabled, setIsFaucetEnabled] = useState<boolean>();
  const { chainId } = useChain();

  useEffect(() => {
    setIsFaucetEnabled(verisModule.getNetwork(chainId).FUNCTIONALITIES.faucet);
  }, [chainId]);

  return isFaucetEnabled;
};

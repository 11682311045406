import { verisModule } from "clients/verisModule";
import { useChain } from "components/providers/ChainProvider";
import { NetworkConfig } from "config/app.config";
import { useEffect, useState } from "react";
import { Address } from "viem";

export const useContractAddress = (
  key: keyof NetworkConfig["CONTRACT_ADDRESSES"]
) => {
  const { chainId } = useChain();

  const [contractAddress, setContractAddress] = useState<Address>(
    verisModule.getContractAddress(key, chainId)
  );

  useEffect(() => {
    setContractAddress(verisModule.getContractAddress(key, chainId));
  }, [chainId]);

  return contractAddress;
};

"use client";
import { useUpdateEffect } from "react-use";

export const useDetectToggleModal = (
  sensors: boolean[],
  onTrue?: () => void,
  onFlase?: () => void
) => {
  for (const sensor of sensors) {
    useUpdateEffect(() => {
      if (sensor) {
        onTrue?.();
      } else {
        onFlase?.();
      }
    }, [sensor]);
  }
};

import classNames from "classnames";
import { ComponentProps, type ElementType } from "react";

type Component = <As extends ElementType = "button">(
  props: ComponentProps<As> & {
    as?: As;
    size?: "xs" | "sm" | "lg";
  }
) => JSX.Element;

const CtaSecondary: Component = (props) => {
  const { children, as, className, size = "sm", ...restProps } = props;
  const Component = as ?? "button";

  const styles = props.disabled
    ? "text-[#9A9A9A] border-[#9A9A9A]"
    : "border-uyellow text-white hover:border-white focus-visible:border-white";

  return (
    <Component
      {...restProps}
      className={classNames(
        styles,
        `bg-secondary border-solid border-2  
        font-bold rounded-full active:contrast-125
        box-border block text-center break-words transition-colors duration-75`,
        {
          "text-2xs md:text-xs px-3 py-1.5": size === "xs",
          "text-xs md:text-sm px-3 max-3xs:px-2 py-2": size === "sm",
          "text-sm md:text-base px-3 max-3xs:px-2 py-2": size === "lg",
        },
        [className]
      )}
    >
      {children}
    </Component>
  );
};

export default CtaSecondary;

import classNames from "classnames";
import type { ComponentProps, ElementType } from "react";

type Component = <As extends ElementType = "button">(
  props: ComponentProps<As> & {
    as?: As;
    size?: "xs" | "sm" | "lg";
  }
) => JSX.Element;

const CtaPrimary: Component = (props) => {
  const { children, as, className, size = "sm", ...restProps } = props;
  const Component = as ?? "button";

  return (
    <Component
      {...restProps}
      className={classNames(
        `border-2 
        active:contrast-125 font-bold rounded-full
        box-border block text-center break-words transition-colors duration-75 button-bg-transition disabled:bg-[#ffffff99] disabled:text-white disabled:border-[#9A9A9A] bg-primary border-primary text-white hover:border-white focus-visible:border-white`,
        {
          "text-2xs md:text-xs px-3 py-1.5": size === "xs",
          "text-xs md:text-sm px-3 max-3xs:px-2 py-2": size === "sm",
          "text-sm md:text-base px-3 max-3xs:px-2 py-2": size === "lg",
        },
        [className]
      )}
    >
      {children}
    </Component>
  );
};

export default CtaPrimary;

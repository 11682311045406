"use client";
import { useEffect, useState } from "react";
import { useErrorAlert } from "./useErrorAlert";
import { handleErrors } from "errors";

type GetKeysWithPromises<T extends {}> = {
  [K in keyof T]: T[K] extends Promise<unknown> ? K : never;
}[keyof T];

type GetReturnType<TDefault, TReturnValue> = TDefault extends undefined
  ? TReturnValue | undefined
  : TReturnValue;

export function usePromisePropertyV2<
  T extends {},
  K extends GetKeysWithPromises<T>,
  TDefault extends Awaited<T[K]> | undefined
>(
  property: K,
  object?: T,
  defaultValue?: TDefault
): { value: GetReturnType<TDefault, Awaited<T[K]>>; updateValue: () => void } {
  const [sensor, toggle] = useState<boolean>(false);
  const [value, setValue] = useState<GetReturnType<TDefault, Awaited<T[K]>>>(
    typeof defaultValue === "function" ? defaultValue() : defaultValue
  );
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);

  useEffect(() => {
    if (object) {
      handleAwaitValue();
    }
  }, [object, sensor]);

  const forceUpdate = () => toggle(!sensor);

  const handleAwaitValue = async () => {
    try {
      if (object) setValue(await object[property]);
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  return { value, updateValue: forceUpdate };
}

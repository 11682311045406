import { Address } from "viem";
import { delegationWalletFactoryContract } from "../../contracts";
import { encodeEventTopics, decodeEventLog } from "viem";
import { delegationWalletFactoryAbi } from "../../contracts/abis/delegationWalletFactoryAbi";
import { OptionsWriteMethod } from "@unlockdfinance/verislabs-web3";

export default async function deployUnlockdWallet(
  externalAddress: Address,
  options?: OptionsWriteMethod
): Promise<Address> {
  const _options = { ...options };

  _options.topicFilter = encodeEventTopics({
    abi: delegationWalletFactoryAbi,
    eventName: "WalletDeployed",
  })[0];

  const output = await delegationWalletFactoryContract.deploy(
    externalAddress,
    _options
  );

  if (!output.log) {
    throw new Error("log not found");
  }

  const topics = decodeEventLog({
    abi: delegationWalletFactoryAbi,
    data: output.log.data,
    topics: output.log.topics,
  });

  return topics.args.safe;
}

import { useEffect, useState } from "react";

type GetKeysWithPromises<T extends {}> = {
  [K in keyof T]: T[K] extends Promise<unknown> ? K : never;
}[keyof T];

type GetKeysWithoutPromises<T extends {}> = {
  [K in keyof T]: T[K] extends undefined
    ? never
    : T[K] extends Promise<unknown>
    ? never
    : K;
}[keyof T];

type GetReturnType<
  T extends {},
  KPromise extends GetKeysWithPromises<T>,
  KNoPromise extends GetKeysWithoutPromises<T>
> = (Record<KPromise, Awaited<T[KPromise]>> &
  Record<KNoPromise, T[KNoPromise]>)[];

export function useArrayPromiseProperties<
  T extends {},
  KPromise extends GetKeysWithPromises<T>,
  KNoPromise extends GetKeysWithoutPromises<T>
>(
  promiseKeys: KPromise[],
  noPromiseKeys: KNoPromise[],
  array: T[]
): GetReturnType<T, KPromise, KNoPromise> | undefined {
  const [newArray, setNewArray] =
    useState<GetReturnType<T, KPromise, KNoPromise>>();

  useEffect(() => {
    if (array) {
      handleAwaitProperties(array);
    }
  }, [array]);

  const handleAwaitProperties = async (array: T[]) => {
    const newArray = await Promise.all(
      array.map((object) => {
        return new Promise<GetReturnType<T, KPromise, KNoPromise>[number]>(
          async (resolve) => {
            const newObject = {} as GetReturnType<
              T,
              KPromise,
              KNoPromise
            >[number];

            await Promise.all(
              promiseKeys.map(
                (key) =>
                  new Promise<void>(async (resolve) => {
                    // @ts-ignore
                    newObject[key] = await object[key];

                    resolve();
                  })
              )
            );

            noPromiseKeys.forEach((key) => {
              // @ts-ignore
              newObject[key] = object[key];
            });

            resolve(newObject);
          }
        );
      })
    );

    setNewArray(newArray);
  };

  return newArray;
}

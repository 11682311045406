"use client";
import { useEffect } from "react";
import useAlertNotification from "../../components/hooks/useAlertNotification";
import { TransactionError, transactionErrorMessage } from "../../errors";

export const useErrorAlert = (error: Error | null) => {
  const [
    isAlertNotificationOpen,
    openAlertNotification,
    closeAlertNotification,
  ] = useAlertNotification();

  useEffect(() => {
    if (error) {
      if (isAlertNotificationOpen) {
        closeAlertNotification();
      }

      openAlertNotification(
        "error",
        error instanceof TransactionError
          ? transactionErrorMessage
          : error.message,
        5000
      );
    }
  }, [error]);
};

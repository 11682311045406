"use client";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useWallet } from "./WalletProvider";
import { useChain } from "components/providers/ChainProvider";
import UnlockdService from "data/UnlockdService";

type IContext = Partial<{
  isLoggedIn: boolean;
  token: string | null;
}>;

const UnlockdSessionContext = createContext<IContext>({});

export const UnlockdSessionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const { address } = useWallet();
  const { chainId } = useChain();

  const isLoggedIn = useMemo<boolean>(
    () => token !== null && token !== undefined,
    [token]
  );

  useEffect(() => {
    if (address && chainId) {
      const unlockdService = UnlockdService.get(chainId);

      const turnOffSub = unlockdService.subscribeToken(address, (token) => {
        setToken(token);
      });

      setToken(unlockdService.token);

      return () => {
        turnOffSub();

        setToken(null);
      };
    }
  }, [address, chainId]);

  return (
    <UnlockdSessionContext.Provider value={{ isLoggedIn, token }}>
      {children}
    </UnlockdSessionContext.Provider>
  );
};

export const useUnlockdSession = () => {
  const sessionObj = useContext(UnlockdSessionContext);

  return sessionObj;
};
